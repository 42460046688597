import { query, collection, where, or, and, onSnapshot, orderBy, limit, startAt, endAt, getDocs } from 'firebase/firestore';
import { db } from '../firebase'


import { Box, Paper, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import OptionCard from './optionCard';

function Results ({ queryParams = null, additional = false, feed = false }) {
  let optionsRef;
  
  const listRef = useRef();
  const scrollToRef = useRef([]);

  const [documents, setDocuments] = useState([]);

  // useEffect(() => {
  //   if (queryParams.geoHash !== 'undefined') {
  //     const getGeoHashData = async () => {
  //       const center = [51.5074, 0.1278];
  //       const radiusInM = 50 * 1000;

  //       // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
  //       // a separate query for each pair. There can be up to 9 pairs of bounds
  //       // depending on overlap, but in most cases there are 4.
  //       const boundsCurrent = geofire.geohashQueryBounds(center, radiusInM);
  //       const promises = [];
  //       for (const b of bounds) {
  //         
  //         const q = query(
  //           collection(db, 'products'), 
  //           orderBy('geohash'), 
  //           startAt(b[0]), 
  //           endAt(b[1]));
          
  //         promises.push(getDocs(q))
  //       }
  //       const snapshots = await Promise.all(promises);
  //       const docs = []
  //       debugger;
  //       for (const snap of snapshots) {
  //         for (const doc of snap.docs) {
  //           docs.push(doc.data());
  //         }
  //       }
  //       setDocuments(docs);
  //     }
  //     getGeoHashData();
  //   }
  // }, [bounds]);

  useEffect(() => {
    console.log(queryParams.promptId);
    setDocuments([])
    const getData = async () => {
      let q;
      if (feed) {
        q = query(collection(db, 'options'),
          where('promptId', '==', queryParams.promptId),
          limit(8)
        );
      } else {
        q = query(collection(db, 'options'), 
          where('promptId', '==', queryParams.promptId),
          limit(8)
        );
      }  
      const unsubscribe = onSnapshot(q, {  }, (querySnapshot) => {
        const msgs = [];
        querySnapshot.docChanges().forEach((change) => {
          console.log('BIG FUCKIN CHANGE: ', change, change.doc.data().title);
        });
        querySnapshot.forEach((doc) => {
            
            msgs.push(doc.data());
        });
        setDocuments(msgs);
      });
      // if (documents.length) {
      //   unsubscribe();
      // }
    }
    getData();
  }, [queryParams.promptId])
  
  if (!documents.length) {
    return <Box sx={{ minHeight: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      ...Loading
    </Box>
  }

  if (!additional && !documents.length) {
    return <Box sx={{ minHeight: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      ...Loading
    </Box>
  }
  return (
    <Box ref={listRef} sx={{ overflow: "scroll" }}>
      {documents.map((data, i) => {
        
        return <OptionCard key={data.id} data={{...data}} classification={queryParams.classification} />
      })}
    </Box>
    )
}

export default Results;