import { Button, TextField, Typography } from "@mui/material"
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { SvgIcon } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { ReactComponent as Logo } from './logo-white.svg';
import CssBaseline from '@mui/material/CssBaseline';
import { Link, useNavigate } from "react-router-dom";
import Users from "./admin/users";
import Prompts from "./admin/prompts";
import Feedback from "./admin/feedback";
import Login from './admin/login'
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db, functions } from "./firebase";
import { getDoc, doc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const Terms = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [type, setType] = useState(null)

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user && !user.isAnonymous) {
        const profileRef = doc(db, 'profiles', user.uid)
        const profile = (await getDoc(profileRef)).data();
        if (profile.role === 'admin') {
          setUser(user);
        }
      }
    })
  }, []);

  const productOrOptions = async () => {
    setIsLoading(true)
    setType('');
    const getOptions = httpsCallable(functions, 'productOrOption');
    // const res = await getOptions({ prompt, conversationId, lat: 51.9015318, lng: 4.4874205, visible: true });
    const res = await getOptions({ prompt });
    const data = res.data;
    setType(data.type);
    setIsLoading(false)
  }

  const handlePromptChange = (event) => {
    setPrompt(event.target.value);
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      productOrOptions()
    }
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={12} sm={12} md={12} lg={12} component={Paper} elevation={0} square sx={{display: "flex", alignItems: "start"}}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", marginBottom: "2em", verticalAlign: "start" }}>
              <Link style={{ color: "#fff", textDecoration: 'none', display: 'flex', verticalAlign: "start", flexDirection: 'row'}} to="/" >
                <Avatar sx={{ m: 1, bgcolor: 'black', margin: "-7px 0 0 0" }}>
                  <SvgIcon><Logo /></SvgIcon>
                </Avatar>
                <Typography component="h1" variant="h5">
                  Tripcompanion
                </Typography>
              </Link>
            </Box>
          </Box>
        </Grid>
        {user ? <Grid flexDirection={"column"} item xs={12} sm={6} md={6} lg={6} component={Paper} elevation={0} square sx={{display: "flex", alignItems: "start"}}>
        <TextField multiline={true} fullWidth={true} onChange={ handlePromptChange } onKeyDown={ handleKeyDown } />
          <Button variant="contained" onClick={() => productOrOptions()}>{isLoading ? '...loading' : 'Test prompt'}</Button>
          <Typography>Type: <strong>{type}</strong></Typography>
        </Grid> : null }
        {user ? <Grid item xs={12} sm={12} md={12} lg={12} component={Paper} elevation={0} square sx={{ display: "flex", alignItems: "start"}}>
          <Grid item xs={2} sm={2} md={2} lg={2} sx={{ height: '100vh', overflow: 'scroll', display: 'flex' }}>
            <Users />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} sx={{ height: '100vh', overflow: 'scroll', display: 'flex' }}>
            <Feedback />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} sx={{ height: '100vh', overflow: 'scroll', display: 'flex' }}>
            <Prompts />
          </Grid>
        </Grid> : <Grid item xs={12} sm={12} md={12} lg={12} component={Paper} elevation={0} square sx={{ display: "flex", alignItems: "start"}}>
          <Login />
        </Grid>}
      </Grid>
  )
}

export default Terms